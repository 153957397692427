/*=========================================
	++ common.js ++ Ver.1.6.1
	Last update 2020.9.10
==========================================*/
/*
* 2020  9/10 全体を一新
* 2019  5/ 7  *object-fit-images 追加
* 2018 11/20  *ブラウザ判定処理を一新
* 2018 10/25  *jQuery 3系 に対応

*/

'usestrict';

//=====================================
//	Detecting Browser & Device Ver.1.4.2
//============================================

//++++++++++++ モバイル/タブレット/ブラウザ判定処理 ++++++++++++

//ブラウザ/デバイス 種別
var isMobile = false,
	isTablet = false,
	isRetina = false,
	isMobileTablet = false,
	isAndroid = false;

//モバイル　タブレット　判定
//https://w3g.jp/blog/js_browser_sniffing2015
var _ua = (function (u) {
	return {
		Tablet: (u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1) ||
			u.indexOf("ipad") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") == -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1) ||
			u.indexOf("kindle") != -1 ||
			u.indexOf("silk") != -1 ||
			u.indexOf("playbook") != -1,
		Mobile: (u.indexOf("windows") != -1 && u.indexOf("phone") != -1) ||
			u.indexOf("iphone") != -1 ||
			u.indexOf("ipod") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") != -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1) |
			u.indexOf("blackberry") != -1
	};
})(window.navigator.userAgent.toLowerCase());

//モバイル
if (_ua.Mobile) {
	isMobile = true;
	isMobileTablet = true;
}
//タブレット
if (_ua.Tablet) {
	isTablet = true;
	isMobileTablet = true;
}

//Retina Display 判定
if (window.retina || window.devicePixelRatio > 1) {
	isRetina = true;
}

//Android 判定
if (/android[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
	isAndroid = true;
}


//============================================
//	デバイス　ブラウザ別の処理
//============================================

//++++++++++++ モバイル/タブレット クラス追加 ++++++++++++

$(function () {
	if (isMobile) {
		//HTMLにclass isMobileを追加
		$('html').addClass('isMobile');
		$('html').addClass('isMobileTablet');
	} else if (isTablet) {
		//HTMLにclass isTabletを追加
		$('html').addClass('isTablet');
		$('html').addClass('isMobileTablet');
	}
	//Retina Display isRetina クラス追加
	if (isRetina) {
		$('html').addClass('isRetina');
	}
});




//============================================
//	汎用スクリプト 
//============================================


//++++++ Copyright ++++++
// Copyright <span class="copyYear">&copy;</span>

$(function () {
	let date = new Date();
	let copyYear = date.getFullYear();
	copyYear = '&copy; ' + copyYear;
	$('.copyYear').html(copyYear);
});


$(function () {
	$(".qa_list dd").hide();
	$(".qa_list dl").on("click", function (e) {
		$('dd', this).slideToggle('fast');
		if ($(this).hasClass('open')) {
			$(this).removeClass('open');
		} else {
			$(this).addClass('open');
		}
	});
});



$(function () {
	$(".menubtn").click(function () {
		$(this).toggleClass('active');
		$('body').toggleClass('no-scroll');
		$("#spnav").toggleClass('panelactive');
	});

	$("#spnav a").click(function () {
		$(".menubtn").removeClass('active');
		$('body').removeClass('no-scroll');
		$("#spnav").removeClass('panelactive');
	});
})


$(function () {
	$(".snav_cat a").click(function () {
		$(this).toggleClass('active');
		$('.snav_sub').toggleClass('show');
	});


})








$(function () {
	if ($(".newsticker ").length) {
		const a = $(".pickup_summary");
		const r = $(".news_ticker_list_item:first-child", a);
		r.addClass("is-active");
		setInterval(function () {
			if ($(".is-active", a).next().length > 0) {
				$(".is-active", a).next().addClass("is-active").end().removeClass("is-active").appendTo(a);
			}
		}, 5000);
	}
});








// 動きのきっかけの起点となるアニメーションの名前を定義
function fadeAnime() {
	// ふわっ
	$('.fadeUpTrigger').each(function () { //fadeUpTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('fadeUp'); // 画面内に入ったらfadeUpというクラス名を追記
		}
	});
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	fadeAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述



function fadeLeftAnime() {
	$('.fadeLeftTrigger').each(function () { //fadeUpTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('fadeLeft'); // 画面内に入ったらfadeUpというクラス名を追記
		}
	});
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	fadeLeftAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述


function fadeRightAnime() {
	$('.fadeRightTrigger').each(function () { //fadeUpTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('fadeRight'); // 画面内に入ったらfadeUpというクラス名を追記
		}
	});
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	fadeRightAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述




function delayScrollAnime() {
	var time = 0.1; //遅延時間を増やす秒数の値
	var value = time;
	$('.delayScroll').each(function () {
		var parent = this; //親要素を取得
		var elemPos = $(this).offset().top; //要素の位置まで来たら
		var scroll = $(window).scrollTop(); //スクロール値を取得
		var windowHeight = $(window).height(); //画面の高さを取得
		var childs = $(this).children(); //子要素を取得

		if (scroll >= elemPos - windowHeight && !$(parent).hasClass("play")) { //指定領域内にスクロールが入ったらまた親要素にクラスplayがなければ
			$(childs).each(function () {

				if (!$(this).hasClass("fadeUp")) { //アニメーションのクラス名が指定されているかどうかをチェック

					$(parent).addClass("play"); //親要素にクラス名playを追加
					$(this).css("animation-delay", value + "s"); //アニメーション遅延のCSS animation-delayを追加し
					$(this).addClass("fadeUp"); //アニメーションのクラス名を追加
					value = value + time; //delay時間を増加させる

					//全ての処理を終わったらplayを外す
					var index = $(childs).index(this);
					if ((childs.length - 1) == index) {
						$(parent).removeClass("play");
					}
				}
			})
		} else {
			$(childs).removeClass("fadeUp"); //アニメーションのクラス名を削除
			value = time; //delay初期値の数値に戻す
		}
	})
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	delayScrollAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述



const scrollThreshold = 400;

function FixedSubAnime() {
	const scroll = $(window).scrollTop();
	const $subNavi = $('.pages_sub_navi');

	if (scroll >= scrollThreshold) {
		$subNavi.addClass('UpMove');
		$subNavi.removeClass('DownMove');
	} else {
		$subNavi.addClass('DownMove');
		$subNavi.removeClass('UpMove');
	}
}

$(window).scroll(function () {
	FixedSubAnime();
});


$(function () {
	var topBtn = $('#page_scroll'),
		scrollHeight = $(document).height(),
		scrollPosition = $(window).height() + $(window).scrollTop();
	topBtn.hide();
	//スクロールが100に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});

	$(window).scroll(function () {
		var scrollHeight = $(document).height();
		var scrollPosition = $(window).height() + $(window).scrollTop();
		if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
			topBtn.toggleClass('last_scl');
		}
	});


	//スクロールしてトップ
	topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});
});



//任意のタブにURLからリンクするための設定
function GethashID(hashIDName) {
	if (hashIDName) {
		$('.tab li').find('a').each(function () {
			var idName = $(this).attr('href');
			if (idName == hashIDName) {
				var parentElm = $(this).parent(); //タブ内のaタグの親要素（li）を取得
				$('.tab li').removeClass("active"); //タブ内のliについているactiveクラスを取り除き
				$(parentElm).addClass("active"); //リンク元の指定されたURLのハッシュタグとタブ内のリンク名が同じであれば、liにactiveクラスを追加
				$(".area").removeClass("is-active"); //もともとついているis-activeクラスを取り除き
				$(hashIDName).addClass("is-active"); //表示させたいエリアのタブリンク名をクリックしたら、表示エリアにis-activeクラスを追加 
			}
		});
	}
}


$('.tab a').on('click', function () {
	var idName = $(this).attr('href');
	GethashID(idName);
	return false;
});




//============================================
//	グローバルナビ　現在地
//============================================




$(function () {
	const url = location.href;
	const dir = url.split("/").filter(e => Boolean(e)); // URLを/で分割して配列に格納
	const lastDir = dir.pop(); // 分割したURLの最後の部分を取り出し
	const gNavs = document.querySelectorAll('.gnav_list ul li a');
	gNavs.forEach(gNav => {
		let currentName = gNav.className;
		if (lastDir === currentName) {
			gNav.classList.add('hilight');
		}
	});
});





// eachTextAnimeにappeartextというクラス名を付ける定義
function EachTextAnimeControl() {
	$('.eachTextAnime').each(function () {
		var elemPos = $(this).offset().top - 50;
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass("appeartext");

		} else {
			$(this).removeClass("appeartext");
		}
	});
}





// 画面が読み込まれたらすぐに動かしたい場合の記述
$(window).on('load', function () {
	//spanタグを追加する
	var element = $(".eachTextAnime");

	element.each(function () {
		var delay = Number($(this).data('delay'));
		var text = $(this).text();
		var textbox = "";
		text.split('').forEach(function (t, i) {
			if (t !== " ") {
				var d = i / 10 + delay;
				textbox += '<span style="animation-delay:' + d + 's;">' + t + '</span>';
			} else {
				textbox += t;
			}
		});
		$(this).html(textbox);
	});

	EachTextAnimeControl(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面が読み込まれたらすぐに動かしたい場合の記述




// 動きのきっかけの起点となるアニメーションの名前を定義
function BgFadeAnime() {

	// 背景色が伸びて出現（左から右）
	$('.bgLRextendTrigger').each(function () { //bgLRextendTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('bgLRextend'); // 画面内に入ったらbgLRextendというクラス名を追記
		} else {
			$(this).removeClass('bgLRextend'); // 画面外に出たらbgLRextendというクラス名を外す
		}
	});

	// 文字列を囲う子要素
	$('.bgappearTrigger').each(function () { //bgappearTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('bgappear'); // 画面内に入ったらbgappearというクラス名を追記
		} else {
			$(this).removeClass('bgappear'); // 画面外に出たらbgappearというクラス名を外す
		}
	});
}


// 画面が読み込まれたらすぐに動かしたい場合の記述
$(window).on('load', function () {
	BgFadeAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面が読み込まれたらすぐに動かしたい場合の記述



new ScrollHint('.js-scrollable', {
	//scrollHintIconAppendClass: 'scroll-hint-icon-white', //背景白
	suggestiveShadow: true, //シャドウを追加
	i18n: {
		scrollable: 'スクロールできます'
	}
});